import React, { useState, useEffect, createRef } from 'react';
import { graphql, navigate } from 'gatsby';
import { shape } from 'prop-types';
import { camelizeKeys } from 'humps';
import Marquee from 'react-fast-marquee';
import { Input } from 'antd';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import SvgIcon from '../../stories/SvgIcon';
import NewsList from '../../components/NewsList/index';
import NewsPagination from '../../stories/NewsPagination';
import { searchResultsPerPage, marqueeSpeed } from '../../utils/constants';
import Container from '../../components/Container';
import DynamicZone from '../../components/DynamicZone';
import Seo from '../../components/seo';

const { Search } = Input;

const SearchPage = ({ data }) => {
  const pageData = camelizeKeys(data.strapi.searchPage);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;
  const { seo } = pageData;
  const allNews = camelizeKeys(data.strapi.news);
  const [search, setSearch] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [page, setPage] = useState(1);
  const [newsToDisplay, setNewsToDisplay] = useState([]);
  const [pageNews, setPageNews] = useState([]);
  const inputRef = createRef();
  const itemsPerPage = pageData?.resultsPerPage || searchResultsPerPage;

  const navigateToNews = (path) => () => {
    navigate(path);
  };

  const handleInput = (e) => {
    const val = e.target.value;
    setSearch(val);
  };

  const handleSearch = () => {
    if (isEmpty(search)) return;
    setNoResults(false);
    const compare = search?.toLowerCase();
    const filteredNews = allNews.filter((i) => i.title?.toLowerCase().includes(compare)
      || i.description?.toLowerCase().includes(compare)
      || i?.keywords?.toLowerCase().includes(compare)
      || i.type?.label?.toLowerCase().includes(compare));

    setNewsToDisplay(filteredNews?.sort((a, b) => (a.date < b.date ? 1 : -1)));
    if (isEmpty(filteredNews)) setNoResults(true);
    if (isEmpty(allNews)) {
      setPageNews([]);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    if (!isEmpty(newsToDisplay)) {
      if (newsToDisplay.length > itemsPerPage) {
        const startIndex = (page - 1) * itemsPerPage;
        const newArr = [...newsToDisplay];
        const news = newArr.splice(startIndex, itemsPerPage);
        setPageNews(news);
      } else setPageNews(newsToDisplay);
    } else setPageNews([]);
  }, [page, newsToDisplay]);

  const handlePageChange = (e) => {
    setPage(e);
  };

  return (
    <Layout headerData={headerData} footerData={footerData} signUpData={signUpData}>
      {seo && (
        <Seo
          title={seo?.title}
          description={seo?.description}
        />
      )}
      <div className="search-page">
        <div className="search-page-input-section">
          <span className="dash" />
          <Marquee speed={marqueeSpeed}>
            <p className="search-page-background-text">{(`${pageData?.backgroundText || 'search '} `).repeat(30)}</p>
          </Marquee>
          <Search
            ref={inputRef}
            bordered={false}
            onChange={handleInput}
            onSearch={handleSearch}
            value={search}
            enterButton={<SvgIcon mode="Search" color="#1D1A1D" />}
          />
        </div>
        {!isEmpty(pageNews) ? (
          <Container>
            <div className="search-results">
              <p
                className="search-results-count col-lg-offset-1"
                id="pagination-anchor"
              >
                {`${newsToDisplay.length} RESULTS`}
              </p>
              <NewsList
                numberOfNews={pageNews.length}
                news={pageNews}
                navigateToNews={navigateToNews}
              />
              {newsToDisplay.length > itemsPerPage && (
                <NewsPagination
                  total={newsToDisplay.length}
                  pageSize={itemsPerPage}
                  showLessItems
                  clickPagination={handlePageChange}
                />
              )}
            </div>
          </Container>
        ) : !noResults && (
          <p className="search-results-count col-lg-offset-1 message">Please enter search query</p>
        )}
        {noResults && (
          <div className="search-results empty">
            <p className="search-results-count no-results col-lg-offset-1">0 RESULTS</p>
          </div>
        )}
        <DynamicZone components={pageData.content} />
      </div>
    </Layout>
  );
};

SearchPage.propTypes = {
  data: shape({
    strapi: shape(),
  }),
};

SearchPage.defaultProps = {
  data: {
    strapi: {},
  },
};

export default SearchPage;

export const searchQuery = graphql`
  query getSearchPage {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      news {
        created_at
        date
        description
        isFeatured
        published_at
        keywords
        slug
        title
        id
        primaryImage {
          url
        }
        secondaryImage {
          url
        }
        commonImage {
          url
        }
        type {
          label
        }
      }
      searchPage {
        id
        backgroundText
        resultsPerPage
        seo {
          description
          id
          title
        }
      }
    }
  }
`;
